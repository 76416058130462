import axios from "axios";

//eslint-disable-next-line
axios.defaults.baseURL = process.env.VUE_APP_API_URL;

export default {
    getOutlets() {
        return axios.get(`/point_ventes`,{headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': '*'
        }});
    },
    postOutlet(obj) {
        return axios.post(`/point_ventes`, obj);
    }
};
