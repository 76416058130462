import moment from "moment";
import fr from "@/core/plugins/fr";
import api from "@/modules/salespoint/services/axios/outlet";
moment.locale('fr', fr);

const state = () => ({
    outlets: [],
    apiRslt: {
        fetch: {
            inProgress: false,
            success: false,
            error: false,
        }
    },
})

const getters = {
    getPdvByDenom: (state) => (denomination) => {
        return state.outlets.find(pdv => pdv.denomination === denomination)
    },
    isOpen: () => (openingArr) =>{ // isOpenByArr
        let today = moment().format('dddd')
        let currentDay = openingArr.find(x => x.day === today)
        if(currentDay){
            let time = moment()
            let start = moment(currentDay.startOpening,'HH:mm')
            let end = moment(currentDay.endOpening,'HH:mm')
            return time.isBetween(start,end) 
        }else{
            return false
        }
        
    },
    isOpenByDenom: (state) => (denom) =>{
        let outlet = state.outlets.find(x => x.denomination === denom)
        let today = moment().format('dddd')
        if(outlet && outlet.openingTime){
            let currentDay = outlet.openingTime.find(x => x.day === today)
            if(currentDay){
                let time = moment()
                let start = moment(currentDay.startOpening,'HH:mm')
                let end = moment(currentDay.endOpening,'HH:mm')
                return time.isBetween(start,end) 
            }
        }
        else{
            return false
        }
    },
}

const actions = {
    loadOutlets({commit}) {
        console.log(api)
        api.getOutlets().then(result => {            
            commit('SAVE_OUTLET', result.data);
        }).catch(error => {
            throw new Error(`API ${error}`);
        });
    },
    addOutlet ({ commit }, newOutlet) {
        commit('SET_API_DATA_FETCH_IN_PROGRESS')
        if (!newOutlet) {
            // do not add empty todos
            return
        }
        api.postOutlet(newOutlet)
            .then((r) => {
                commit('SET_API_DATA_FETCH_SUCCESS',r.data)
                commit('ADD_OUTLET', r.data)
            })
            .catch((e) => {
                commit('SET_API_DATA_FETCH_ERROR', e)
            });
    },
}

const mutations = {
    ADD_OUTLET (state, pointvente) {
        state.outlets.push(pointvente)
    },
    SAVE_OUTLET(state, outlets) {
        state.outlets = outlets;
    },

    // Result api
    'SET_API_DATA_FETCH_IN_PROGRESS': (state) => {
        state.apiRslt.fetch.inProgress = true
        state.apiRslt.fetch.completed = false
        state.apiRslt.fetch.success = false
        state.apiRslt.fetch.error = false
    },
    'SET_API_DATA_FETCH_SUCCESS': (state) => {
        state.apiRslt.fetch.inProgress = false
        state.apiRslt.fetch.completed = true
        state.apiRslt.fetch.success = true
        state.apiRslt.fetch.error = false
    },
    'SET_API_DATA_FETCH_ERROR': (state) => {
        state.apiRslt.fetch.inProgress = false
        state.apiRslt.fetch.completed = true
        state.apiRslt.fetch.success = false
        state.apiRslt.fetch.error = true
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
